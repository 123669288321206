import React from "react";
import "../assets/sass/_flipCard.scss";
import Img from "gatsby-image";
import Fade from "react-reveal/Fade";

const FlipCard = ({
  image,
  title,
  icon,
  frontText,
  frontTextSmall,
  backText,
  nrCols = 3,
  cta,
  onClick=()=>{}
}) => {
  return (
    <div className={`col-sm-12 col-md-${12 / nrCols} flipCard`} onClick={onClick}>
      <Fade bottom>
        <div
          className="image-flip"
          onTouchStart={(e) => e.currentTarget.classList.toggle("hover")}
        >
          <div className="mainflip">
            <div className={backText ? "frontside" : "front"}>
              <div className="card">
                <div className="card-body text-center">
                  {image && (
                    <Img
                      className={"img-fluid"}
                      fixed={image.childImageSharp.fixed}
                    />
                  )}
                  {icon && <span className="card-icon">{icon}</span>}
                  <h3 className="card-title">{title}</h3>
                  {frontText && (
                    <p className="card-text">
                      {frontText.split("\\n").map((v,k) => (
                        <div key={`card-text${k}`}>
                          {v}
                        </div>
                      ))}
                    </p>
                  )}
                  {frontTextSmall && (
                    <p className="card-text-small">
                      {typeof frontTextSmall === "string"
                        ? frontTextSmall.split("\\n").map((v, k) => (
                            <div key={`text-small${k}`}>
                              {v}
                              <br />
                            </div>
                          ))
                        : frontTextSmall}
                    </p>
                  )}
                  {cta && cta}
                </div>
              </div>
            </div>
            {backText && (
              <div className="backside">
                <div className="card">
                  <div className="card-body text-center mt-4">
                    <h4 className="card-title">{title}</h4>
                    <p className="card-text">{backText}</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </Fade>
    </div>
  );
};

export default FlipCard;
