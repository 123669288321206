import React from "react";
import Fade from "react-reveal/Fade";
import Img from "gatsby-image";
import { StaticQuery, graphql } from "gatsby";

import Slider from "../Slider";
import "../../assets/sass/_reviews.scss";
const feedback = JSON.parse(`{
  "feedback": [
    {
      "name": "Érica Cagali",
      "description":
        "Lugar incrível e com vários profissionais na Área da Saúde. \\nEm um só lugar com várias especialidades (Psicologia, Acupuntura,Terapia Familiar,Estética e Massoterapia,Terapias holísticas etc)"
    },
    {
      "name": "",
      "description":
        "É um espaço com muito boa energia, só dá vontade de estar lá sempre."
    },
    {
      "name": "Margarida Pedroso",
      "description":
        "Estou encantada com a EMMIM! Não falta nada :) \\nA dinâmica do cowork está a construir-se. Penso que leva o seu tempo. É um privilégio fazer parte da infância da EMMIM :)"
    },
    {
      "name": "",
      "description":
        "Ótima localização e acessibilidade para os meus clientes. \\nAmbiente super agradável."
    },
    {
      "name": "Manuela Teixeira",
      "description":
        "Um espaço terapêutico, acolhedor, e com atendimento personalizado de excelência."
    },
    {
      "name": "",
      "description":
        "Adoro trabalhar na Emmim. desde o primeiro dia que me sinto em casa. \\nToda a equipa foi e é imensamente prestável, acolhedora e profissional comigo. \\nO Espaço é sereno, acolhedor e perfeito para as minhas consultas. não podia estar mais satisfeita e feliz por vos ter encontrado no meu caminho. \\nUm grande obrigada querida Emmim"
    }
  ]
}`);
const Reviews = ({
  title = "O que dizem os nossos clientes?",
  subtitle = "Conheça a opinião de quem nos conhece melhor",
  ...props
}) => {
  return (
    <StaticQuery
      query={graphql`
        query Testemunials {
          margarida: file(relativePath: { eq: "margaridaYoutube.png" }) {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          cibelleYoutube: file(relativePath: { eq: "cibelleYoutube.png" }) {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          lilisYT: file(relativePath: { eq: "lilisYT.png" }) {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          paulaYT: file(relativePath: { eq: "PaulaCarvalheiraYT.png" }) {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={(data) => {
        return (
          <div
            className={["reviews pt-5", props.className].join(" ")}
            id={props.itemLink}
          >
            <div className={"reviewsContainer"}>
              <div className={"titles"}>
                <Fade bottom>
                  <h1 className={`text-center marker`}>{title}</h1>
                  <h3 className={`text-center marker2`}>{subtitle}</h3>
                </Fade>
              </div>
              <Fade bottom>
                <div className="container ">
                  <div className="row mt-5 mb-5">
                    <div className="col-sm-12 col-md-3">
                      <a href="https://youtu.be/luJsOWfErBM" target="_blank">
                        <Img
                          fluid={data.margarida.childImageSharp.fluid}
                          className={"photo img-fluid"}
                        />
                      </a>
                    </div>

                    <div className="col-sm-12 col-md-3">
                      <a
                        href="https://www.youtube.com/watch?v=qARLsOT--Nw"
                        target="_blank"
                      >
                        <Img
                          fluid={data.cibelleYoutube.childImageSharp.fluid}
                          className={"photo img-fluid"}
                        />
                      </a>
                    </div>
                    <div className="col-sm-12 col-md-3">
                      <a href="https://youtu.be/3uDWG2ThuxM" target="_blank">
                        <Img
                          fluid={data.paulaYT.childImageSharp.fluid}
                          className={"photo img-fluid"}
                        />
                      </a>
                    </div>
                    <div className="col-sm-12 col-md-3">
                      <a
                        href="https://youtu.be/_SUvEPtVH60"
                        target="_blank"
                      >
                        <Img
                          fluid={data.lilisYT.childImageSharp.fluid}
                          className={"photo img-fluid"}
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </Fade>
              <h2 className={`text-center`}>E ainda...</h2>
              <Slider
                className={`testemonialContainer`}
                content={feedback.feedback.map((el, i) => {
                  return (
                    <div
                      key={`reviews${i}`}
                      className={`sliderContent testemonial `}
                    >
                      <div className="description">
                        {el.description.split("\\n").map((i, key) => {
                          return <p key={`rev${key}`}>{i}</p>;
                        })}
                      </div>
                      <p>
                        {el.name && <span className={"name"}>{el.name}</span>}
                        {el.link && (
                          <a
                            href={el.link}
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            <button>{el.actionDesc}</button>
                          </a>
                        )}
                      </p>
                    </div>
                  );
                })}
              />
            </div>
          </div>
        );
      }}
    />
  );
};

export default Reviews;
