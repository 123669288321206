import React from "react";
import config from "../../config";

export default function BookVisit({
  bg = "calendar",
  title = (
    <>
      Pronto para ter o seu consultório no centro de <b>LISBOA</b>{" "}
      <span className="underline">sem ter que investir uma fortuna?</span>
    </>
  ),
  subtitle = (
    <h3>
      Conheça a EMMIM, a nossa equipa e saiba como podemos ajudá-lo a tornar o seu sonho
      realidade
    </h3>
  ),
  capture = false,
}) {
  return (
    <section className={`contact-section ${bg}`} id="contacts">
      <svg viewBox="0 0 1920 355" className="wave">
        <g
          id="Page-1"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g transform="translate(0.000000, -402.000000)">
            <path
              d="M0,439.134243 C175.04074,464.89273 327.944386,477.771974 458.710937,477.771974 C654.860765,477.771974 870.645295,442.632362 1205.9828,410.192501 C1429.54114,388.565926 1667.54687,411.092417 1920,477.771974 L1920,757 L1017.15166,757 L0,757 L0,439.134243 Z"
              id="Path"
            ></path>
          </g>
        </g>
      </svg>
      <div className="pt-5 mb-0 text-center content">
        <h2 className="text-center">{title}</h2>
        {subtitle && <h4 className="text-center">{subtitle}</h4>}
        <div className="p-5">
          <a
            href={`${config.calendly}`}
            className="btn btn-white btn-cta "
            target="_blank"
            title="Marcar visita"
          >
            Marcar Visita
          </a>
        </div>
        <h2 className="pt-5">Questões?</h2>
        <h3 className="">
          <a className="btn-white" href="mailto:geral@emmim.pt" title="Email">
            geral@emmim.pt
          </a>{" "}
        </h3>
      </div>
    </section>
  );
}
