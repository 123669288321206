import React from "react";

import SliderComp from "react-animated-slider";
import "react-animated-slider/build/horizontal.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons/faChevronLeft";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons/faChevronRight";
import "../assets/sass/_slider.scss";
library.add(...[faChevronLeft, faChevronRight]);

const Slider = ({ className, content }) => {
  return (
    <SliderComp
      nextButton={<FontAwesomeIcon icon={"chevron-right"} />}
      previousButton={<FontAwesomeIcon icon={"chevron-left"} />}
      className={`slider sliderWrapper ${className}`}
    >
      {content}
    </SliderComp>
  );
};

export default Slider;
